<template>
  <v-form ref="infoForm" class="map-form">
    <div class="d-flex flex-grow-1">
      <div
        class="map-container flex-grow-1"
        style="height: calc(100vh - 135px)"
      >
        <zones-map
          ref="zonesMap"
          :center="center"
          :zoom="zoom"
          :map-created="onMapCreated"
          map-type-id="roadmap"
          :options="mapOptions"
          :zones-loaded="onZonesLoaded"
          :zones="zones"
          :shouldZoomToCenter="shouldZoomToCenter"
          :zonePolygonSelected="zonePolygonSelected"
        >
          <gmap-marker
            v-for="m in filteredEntryPoints"
            :key="m"
            :position="m.position"
            :icon="m.icon"
            :draggable="true"
            @dragend="onDragendEntryPoint($event, m)"
            @click="onClickEntryPoint(m)"
          >
          </gmap-marker>
        </zones-map>
      </div>
    </div>
  </v-form>
</template>

<script>
import ZonesMap from '@/components/Common/ZonesMap.vue'
import { MAP_SETTINGS } from '@/helpers/map'
import math from '@/components/Map/calculations'
import helpers from '@/helpers'
export default {
  components: {
    ZonesMap
  },
  props: {
    zones: {
      type: Array,
      required: true
    },
    zonesLoaded: {
      type: Function,
      default: () => {}
    },
    currentMerchant: {
      type: Object,
      default: null
    },
    shouldZoomToCenter: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      center: { lat: 60.165824, lng: 24.930189 },
      zoom: 10,
      marker: {
        position: null
      },
      address: '',
      mapOptions: {
        styles: MAP_SETTINGS.styles,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        tilt: 0,
        fullscreenControlOptions: { position: 9 } // google.maps.ControlPosition.RIGHT_BOTTOM is just 9
      },
      selectedPolygon: null,
      entryPoints: [],
      geocoder: null
    }
  },
  methods: {
    async zonePolygonSelected(polygon) {
      try {
        await this.$store.dispatch('setShowOverlay', true)
        await this.$router.push({
          name: 'Location',
          params: {
            merchantId: polygon.options.merchantId,
            locationId: polygon.options.zone_id
          }
        })
      } finally {
        await this.$store.dispatch('setShowOverlay', false)
      }
    },

    addMarker(event) {
      this.marker.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
    },
    /**
     * get entry points data from the zone object
     * @param {Object} zone - zone object
     */
    getEntryPoints(zone) {
      this.entryPoints = []
      const entryPoints = zone.coordinates.data.entry_points.data
      if (entryPoints) {
        entryPoints.forEach((point) => {
          this.entryPoints.push({
            entry_point_id: point.entry_point_id,
            position: {
              lat: parseFloat(point.lat),
              lng: parseFloat(point.lng)
            },
            icon: this.getEntryPointIcon(Number(point.angle)),
            delete: false
          })
        })
      }
    },
    /**
     * get icon object for google map marker
     * @param {number} rotation - rotation for the marker
     */
    getEntryPointIcon(rotation) {
      return {
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 3,
        strokeColor: '#00BE14',
        fillColor: '#00BE14',
        fillOpacity: 1,
        strokeWeight: 2,
        rotation
      }
    },
    getMapObject() {
      const zonesMap = this.$refs.zonesMap
      const mapObject = zonesMap.$refs.map.$mapObject
      return mapObject
    },
    onMapCreated() {},
    onZonesLoaded(polygons) {
      // this.selectedPolygon = polygons.filter(polygon => helpers.areSameZone(polygon.options.zone_id, this.zone.zone_id))[0]
      // if (this.selectedPolygon) {
      //   this.selectedPolygon.options.editable = true
      // }
      this.zonesLoaded(polygons)
    },
    onClickEntryPoint(point) {
      point.delete = true
    },
    /**
     * Move the entry point to the new position when mouse drag ended
     * @param {Event} $event - google map event
     * @param {Object} point - a marker for the entry point
     */
    onDragendEntryPoint($event, point) {
      // set the dragged and rotation angle
      const positionAndRotation = this.getPositionAndRotation($event)
      point.position = positionAndRotation.position
      point.icon = {
        ...point.icon,
        rotation: positionAndRotation.icon.rotation
      }
    },
    /**
     * Method for finding selected polygon map object
     */
    selectedPolygonObject() {
      try {
        const zonesMap = this.$refs.zonesMap
        const polygonsRef = zonesMap.$refs.polygons
        return polygonsRef.find((p) =>
          helpers.areSameZone(p.options.zone_id, this.zone.zone_id)
        )
      } catch (e) {
        return null
      }
    },

    /**
     * When the polygon's path changed, check if there any path left, othewise enable drawing new
     */
    onPolygonPathChanged() {
      const path = this.selectedPolygonObject().$polygonObject.getPath()
      // when the selected polygon has no paths, allow to draw new polygon
      if (!path) {
        this.enableDrawingPolygon = true
      }
    },
    /**
     * Get the center point of polygon. Use when zone boundaries is drawn, get the boundary center to set as map center
     * @param {polygon} polygon
     */
    getPolygonCenter(polygon) {
      const bounds = new window.google.maps.LatLngBounds()
      polygon.getPath().forEach((edge) => {
        bounds.extend(edge)
      })
      return bounds.getCenter()
    },

    /**
     * Get calculated position and entry point rotation from $event
     * @param {Event} $event - google map event
     */
    getPositionAndRotation($event) {
      const closestPoint = math.getClosestPointOnPolygon(
        $event.latLng,
        this.polygon
      )
      const rotation = math.getRotation(closestPoint, this.polygon)
      return {
        position: {
          lat: closestPoint.y,
          lng: closestPoint.x
        },
        icon: {
          rotation
        }
      }
    },
    /**
     * Before loading new data, clear current data
     */
    resetCurrentData() {
      this.entryPoints = []
      if (this.selectedPolygon) {
        this.selectedPolygon.options.editable = false
      }
    }
  },
  computed: {
    filteredEntryPoints() {
      return this.entryPoints.filter((m) => !m.delete)
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.d-flex {
  display: flex;
}
.flex-grow-1 {
  flex-grow: 1;
}
.form-label {
  padding-top: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
  color: rgba(31, 31, 31, 0.5);
}
.map-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.fill-space {
  height: 100%;
}
.map-form {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.form-wrapper {
  width: 339px !important;
  height: 100% !important;
}

.map-container {
  box-sizing: border-box;
}

.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}
</style>
