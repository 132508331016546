<template>
  <div>
    <v-form ref="form" class="form add-user-table">
      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="text-input-label">Country</div>
          <v-select
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="currentMerchantFormData.country_id"
            :rules="[rules.required]"
            class="pkmn-input error-border"
            item-value="id"
            item-text="name"
            :items="countries"
          />
        </v-col>
      </v-row>

      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="text-input-label">Provider type</div>
          <v-select
            solo
            flat
            dense
            background-color="#F6F6F6"
            :rules="[rules.required]"
            v-model="currentMerchantFormData.merchant_type_id"
            class="pkmn-input error-border"
            :items="providerTypes"
          />
        </v-col>
      </v-row>

      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="text-input-label">Default VAT</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="value.vat_percentage"
            class="pkmn-input pkmn-input--inline"
            suffix="%"
            type="number"
            min="0"
            :rules="[rules.onlyPositive]"
            :hint="vatHint"
            persistent-hint
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <div class="text-input-label">Currency</div>
          <v-select
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="value.currency_id"
            class="pkmn-input"
            :items="currencyTypes"
            item-value="id"
            item-text="name"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="text-input-label">Merchant kick back in</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="value.kick_back_fee_percentage"
            class="pkmn-input pkmn-input--inline"
            suffix="%"
            type="number"
            min="0"
            :rules="[rules.onlyPositive]"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="text-input-label">Merchant kick back in</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="value.kick_back_fee_fixed"
            class="pkmn-input"
            :suffix="currencyIcon"
            type="number"
            min="0"
            :rules="[rules.onlyPositive]"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="text-input-label">ParkMan kick back in</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="value.commission_percentage"
            class="pkmn-input pkmn-input--inline"
            suffix="%"
            type="number"
            min="0"
            :rules="[rules.onlyPositive]"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="text-input-label">ParkMan kick back in</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="value.commission_fixed"
            class="pkmn-input"
            :suffix="currencyIcon"
            type="number"
            min="0"
            :rules="[rules.onlyPositive]"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <div class="delete-button--container" @click="deleteMerchant">
        <div class="delete-button-content">
          <div class="delete-button-title">
            <span>Delete/disable account</span>
          </div>
        </div>
      </div>
    </v-form>

    <form-delete-merchant
      v-if="isDeleteMerchant"
      :data="this.data"
      @close="isDeleteMerchant = false"
      @merchantDeleted="merchantDeleted"
    />
  </div>
</template>

<script>
import FormDeleteMerchant from '@/components/Merchant/Form/FormDeleteMerchant'
import rules from '@/helpers/validation'
import * as config from '@/config/config'
import merchantHelpers from '@/helpers/merchant'
import { CurrencyTypesEnum } from '@/enums'

export default {
  name: 'AddMerchantSettings',
  components: {
    FormDeleteMerchant
  },
  props: {
    value: Object
  },
  mounted() {
    this.currencySuffix = this.value.currency
  },
  computed: {
    countries() {
      return this.$store.getters.countries
    },
    currentMerchantFormData() {
      return this.$store.getters.currentMerchant
    },
    currencyIcon() {
      if (this.value.currency_id) {
        return this.currencyTypes[this.value.currency_id - 1].symbol
      }
      return null
    },
    vatHint() {
      return this.value.vat_percentage === '' || this.value.vat_percentage === null
        ? 'Country VAT by default'
        : ''
    }
  },
  methods: {
    deleteMerchant() {
      this.isDeleteMerchant = true
    },
    async merchantDeleted() {
      this.isDeleteMerchant = false
      await this.$router.push({ name: 'AllMerchants' })
      await this.$store.dispatch('setSystemMessage', {
        message: `${this.value.name} was deleted`,
        type: 'error'
      })
      await merchantHelpers.fetchAllMerchants()
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_ALL)
    }
  },
  data() {
    return {
      rules: rules,
      providerTypes: [
        {
          text: 'Municipality',
          value: 1
        },
        {
          text: 'Company',
          value: 2
        },
        {
          text: 'Individual',
          value: 3
        },
        {
          text: 'Restricted',
          value: 4
        }
      ],
      currencies: ['USD', 'EUR', 'GBP', 'DKK'],
      currencyTypes: CurrencyTypesEnum,
      isDeleteMerchant: false,
      data: {
        name: 'Merchant A'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.submit-button {
  display: flex;
  padding-top: 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--button-attention, #eb5757);
  text-align: center;
  /* Desktop/Button/idle */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.form {
  width: 339px;
  height: 100% !important;

  .v-select .v-select__selections {
    height: 48px;
    // padding: 0 12px;
  }
  .v-menu__content {
    background-color: #fff;
    .v-picker__title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: 339px) {
    width: 100%;
  }
}
</style>

<style lang="scss">
.delete-button--container {
  width: 314px;
  height: 46px;
  padding-top: 24px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;

  .delete-button-content {
    text-align: center;
    color: #eb5757;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 22.4px;
    word-wrap: break-word;
    cursor: pointer;
  }
}

.add-user-table {
  padding-top: 12px !important;
}

</style>
