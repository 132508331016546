<template>
  <div>
    <div>
      <v-row>
        <v-col cols="23">
          <data-table-users
            :data="users"
            :showEdit="false"
            @delete="deleteUser"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DataTableUsers from '@/components/Merchant/DataTable/DataTableUsers'

export default {
  name: 'MerchantUsers',
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  components: {
    DataTableUsers
  },
  methods: {
    deleteUser(user) {
      this.$emit('delete', user)
    }
  }
}
</script>

<style lang="scss" scoped>
.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}
</style>
