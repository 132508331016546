<template>
  <div class="mt-8">
    <v-data-table
      class="data__table pb-12"
      ref="table"
      :headers="headers"
      :items="parkingsList"
      fixed-header
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:item.start_time="{ item }">
        {{ moment(item.start_time).format('DD.MM.YY HH:mm') }}
      </template>
      <template v-slot:item.end_time="{ item }">
        {{ moment(item.end_time).format('DD.MM.YY HH:mm') }}
      </template>
      <template v-slot:item.revenue="{ item }">
        {{ roundToTwoSigns(item.revenue) }}
      </template>
      <template v-slot:item.without_vat="{ item }">
        {{ roundToTwoSigns(item.without_vat) }}
      </template>
      <template v-slot:body.append>
        <div v-if="loadMoreLicencePlatesBtnState" class="load-more-row">
          <v-btn
            class="pkmn-button"
            outlined
            color="primary"
            @click="loadMoreLicencePlateSearch"
          >
            {{ $t('load_more') }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import auth from '@/auth'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'LicencePlateSearchTable',
  props: {
    parkingsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      auth: auth,
      moment: moment,
      headers: [
        { text: this.$t('location'), value: 'location.name' },
        { text: this.$t('licence_plate'), value: 'car_registration_number' },
        { text: this.$t('start_time'), value: 'start_time' },
        { text: this.$t('stop_time'), value: 'end_time' },
        { text: this.$t('discount'), value: 'discount' },
        { text: this.$t('revenue'), value: 'revenue' },
        { text: this.$t('excl_VAT'), value: 'without_vat' }
      ],
      mobileBreakpoint: 767 // screen width in pixel to switch to mobile view
    }
  },
  computed: {
    ...mapGetters(['loadMoreLicencePlatesBtnState']),
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    loadMoreLicencePlateSearch() {
      this.$emit('loadMore')
    },
    roundToTwoSigns(num) {
      return num ? num.toFixed(2) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.load-more-row {
  position: absolute;
  bottom: 0;
  left: calc(50% - 64px);
}
</style>
