<template>
  <div class="add-merchant-info-wrapper">
    <v-form class="form add-user-table">
      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="text-input-label">Parking provider name</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            class="pkmn-input"
            v-model="currentMerchant.name"
            :disabled="!(auth.isParkmanAdmin() || auth.isMerchantAdmin())"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="text-input-label">Business ID</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            class="pkmn-input"
            v-model="currentMerchant.business_id"
            :disabled="!(auth.isParkmanAdmin() || auth.isMerchantAdmin())"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="text-input-label">Contact email</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            class="pkmn-input"
            v-model="currentMerchant.contact_email"
            :rules="[rules.email]"
            :disabled="!(auth.isParkmanAdmin() || auth.isMerchantAdmin())"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="custom-row">
        <div
          class="custom-icon"
          @click="openImagePicker"
          ref="placeholder"
          :style="{
            backgroundImage: `url(${getLogoUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain'
          }"
        >
          <div class="icon-line">
            <input
              type="file"
              ref="fileInput"
              style="display: none"
              accept="image/png"
            />
          </div>
        </div>
        <v-col cols="12" sm="7" class="custom-content" @click="openImagePicker">
          <div class="content-title">Upload logo</div>
          <div class="content-description">400x400 png</div>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          v-if="auth.isParkmanAdmin() || auth.isMerchantAdmin()"
        >
          <v-btn
            v-if="showDeleteButton && getLogoUrl"
            icon
            @click="removeImage"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="fileError">
          <span class="error-color file-error">Logo size exceeds 2 MB</span>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
/* import FormAddUser from './FormAddUser.vue' */
import auth from '@/auth'
import rules from '@/helpers/validation'

export default {
  name: 'MerchantInfo',
  data() {
    return {
      rules: rules,
      fileError: false,
      auth: auth,
      logoImage: null,
      showDeleteButton: true,
      tab: null,
      items: [
        { tab: 'Advanced Settings', content: 'TabSetting' },
        { tab: 'Payouts', content: 'TabPayouts' }
      ]
    }
  },
  methods: {
    submit() {
      this.$emit('submit', this.formData)
    },
    openImagePicker() {
      if (!(auth.isParkmanAdmin() || auth.isMerchantAdmin())) {
        return
      }

      const input = this.$refs.fileInput
      input.click()
      input.addEventListener('change', () => {
        const file = input.files[0]
        const fileSize = file.size / 1024 / 1024 // in MiB
        this.removeImage()
        if (fileSize > 2) {
          this.fileError = true
          return
        }
        this.fileError = false
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          this.logoImage = reader.result
          const placeholder = this.$refs.placeholder
          placeholder.style.backgroundImage = `url(${this.logoImage})`
          this.currentMerchant.logo = this.logoImage
          this.showDeleteButton = true
        })
        reader.readAsDataURL(file)
      })
    },
    removeImage() {
      this.logoImage = null // reset logoImage to empty string
      this.showDeleteButton = false // set showDeleteButton to false
      this.currentMerchant.logo = null
    }
  },
  computed: {
    currentMerchant() {
      return this.$store.getters.currentMerchant
    },
    getLogoUrl() {
      return this.currentMerchant.logo
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}
.file-error {
  display: block;
  font-size: 14px;
  position: relative;
  top: -10px;
}

.image-placeholder {
  width: 56px;
  height: 56px;
  background: $color-gray-background;
  color: $text-color-light;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.add-merchant-info-wrapper {
  height: 100%;
  width: 100%;
}

.form {
  width: 339px;
  height: 100% !important;

  .v-select .v-select__selections {
    height: 48px;
    // padding: 0 12px;
  }
  .v-menu__content {
    background-color: #fff;
    .v-picker__title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: 339px) {
    width: 100%;
  }
}

.custom-row {
  width: 339px;
  height: 64px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  padding-left: 12px;
  .custom-icon {
    padding: 13px 4px;
    border-radius: 40px;
    border: 0.5px solid #e5e5e5;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;

    .icon-line {
      width: 24px;
      height: 6px;
    }
  }

  .custom-content {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    display: inline-flex;
    cursor: pointer;
    .content-title {
      align-self: stretch;
      color: #333333;
      font-size: 14px;
      font-family: Roboto;
      font-weight: 500;
      line-height: 19.6px;
      word-wrap: break-word;
    }

    .content-description {
      align-self: stretch;
      color: rgba(31, 31, 31, 0.5);
      font-size: 12px;
      font-family: Roboto;
      font-weight: 400;
      line-height: 15.6px;
      word-wrap: break-word;
    }
  }
}
.add-user-table {
  padding-top: 12px !important;
}
</style>
