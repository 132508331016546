<template>
  <div class="reports__data mt-8">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="data"
      fixed-header
      hide-default-footer
      disable-pagination
      must-sort
      :mobile-breakpoint="mobileBreakpoint"
    >
    <template v-slot:item.period_start="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <span class="ellipsed-text" v-bind="attrs" v-on="on">
              {{ revenuePeriod(item) }}
            </span>
          </template>
          <span>
            {{ revenuePeriod(item) }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.generated_on="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <span class="ellipsed-text" v-bind="attrs" v-on="on">
              {{ reportDate(item) }}
            </span>
          </template>
          <span>
            {{ reportDate(item) }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.payout_sum="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <span class="ellipsed-text" v-bind="attrs" v-on="on">
              {{ sum(item) }}
            </span>
          </template>
          <span>
            {{ reportDate(item) }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.receivers="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <span class="ellipsed-text" v-bind="attrs" v-on="on">
              {{ formatedReceivers(item.receivers) }}
            </span>
          </template>
          <span>
            {{ formatedReceivers(item.receivers) }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-hover v-slot="{ hover }">
          <v-icon
            :color="hover ? 'primary' : '#B9B9B9'"
            @click="downloadReport(item)"
          >
            mdi-download
          </v-icon>
        </v-hover>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DataTableReports',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headers: [
        { text: 'Revenue period', value: 'period_start' },
        { text: 'Report date', value: 'generated_on' },
        {
          text: 'Report receiver(s)',
          value: 'receivers',
          cellClass: 'tooltip-column'
        },
        { text: 'Report ID', value: 'id' },
        { text: 'Payout sum', value: 'payout_sum' },
        { text: '', value: 'action', sortable: false }
      ],
      mobileBreakpoint: 767 // screen width in pixel to switch to mobile view
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    async downloadReport(item) {
      this.$emit('download', item.id)
    },
    formatedReceivers(list) {
      return list.join(', ')
    },
    revenuePeriod(item) {
      const start = moment(item.period_start, 'YYYY-MM-DD').format('DD.MM.YYYY')
      const end = moment(item.period_end, 'YYYY-MM-DD').format('DD.MM.YYYY')
      return `${start}-${end}`
    },
    reportDate(item) {
      const date = moment(item.generated_on, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')
      return date
    },
    sum(item) {
      return `${item.payout_sum} ${item.payout_currency}`
    }
  }
}
</script>
<style lang="scss" scoped>
.reports__data .theme--light:focus:before {
  opacity: 1 !important;
}

::v-deep .tooltip-column {
  max-width: 145px;
}
::v-deep .ellipsed-text {
  width: 100%;
  display: block;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

::v-deep th {
  text-wrap: nowrap;
}
</style>
