<template>
  <v-menu
    v-model="isDropdownVisible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    class="menu-style"
  >
    <template v-slot:activator="{}">
      <button class="my-button" @click="toggleDropdown">
        {{ buttonLabel }} <i class="fa fa-chevron-down"></i>
        <img src="@/assets/dropdown_arrow_green.svg" alt="Dropdown Icon" />
      </button>
    </template>
    <!-- <div class="dropdown">
      <div class="date-inputs">
        <div class="date-input">
          <label for="start-date">From</label>
          <input
            type="text"
            id="start-date"
            v-model="startDate"
            @input="updateDates"
            placeholder="dd.mm.yyyy"
          />
        </div>
        <div class="date-input">
          <label for="end-date">To</label>
          <input
            type="text"
            id="end-date"
            v-model="endDate"
            @input="updateDates"
            placeholder="dd.mm.yyyy"
          />
        </div>
      </div>
    </div> -->

    <v-date-picker
      v-if="isDatePicker"
      v-model="dates"
      @change="datesChanged"
      range
      color="primary"
      no-title
    />
    <v-date-picker
      v-else
      v-model="month"
      @change="monthsChanged"
      type="month"
      color="primary"
      no-title
      :min="min"
    />
  </v-menu>
</template>
<script>
import moment from 'moment'

export default {
  name: 'PeriodPicker',
  props: {
    initialStartDate: {
      type: String,
      default: ''
    },
    initialEndDate: {
      type: String,
      default: ''
    },
    isDatePicker: {
      type: Boolean,
      default: true
    },
    minStartDate: {
      type: String,
      default: ''
    }
  },
  data() {
    const defaultStartDate = moment().startOf('month').format('DD.MM.YYYY')
    const defaultEndDate = moment().endOf('month').format('DD.MM.YYYY')
    return {
      menuVisibleFrom: null,
      isDropdownVisible: false,
      dates: [],
      startDate: this.initialStartDate || defaultStartDate,
      endDate: this.initialEndDate || defaultEndDate,
      month: null,
      min: this.minStartDate === '' ? null : this.minStartDate,
      monthYearLabel: moment().startOf('month').format('MMM YYYY')
    }
  },
  computed: {
    buttonLabel() {
      return this.isDatePicker ? `${this.startDate}-${this.endDate}` : this.monthYearLabel
    }
  },
  watch: {
    initialStartDate(newVal) {
      if (newVal) {
        this.startDate = newVal;
      }
    },
    initialEndDate(newVal) {
      if (newVal) {
        this.endDate = newVal;
      }
    },
    startDate: {
      immediate: true,
      handler(newDate) {
        this.dates[0] = moment(newDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
    },
    endDate: {
      immediate: true,
      handler(newDate) {
        this.dates[1] = moment(newDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    monthsChanged(val) {
      const validDateStr = `${val}-01`;  
      const selectedDate = new Date(validDateStr);

      // Extract year and month
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth(); // 0-based (Jan = 0, Feb = 1, etc.)

      // Create start of month: day = 1
      const startDate = new Date(year, month, 1);

      // Create end of month: day = 0 of next month means "last day of current month"
      const endDate = new Date(year, month + 1, 0);

      this.startDate = moment(startDate).format('DD.MM.YYYY')
      this.endDate   = moment(endDate).format('DD.MM.YYYY')

      this.monthYearLabel = moment(startDate).format('MMM YYYY');
      this.$emit('periodUpdated', {
          startDate: this.startDate,
          endDate: this.endDate
        })

        this.isDropdownVisible = false
    },
    datesChanged() {
      const [startDate, endDate] = this.dates
      this.startDate = moment(endDate).isBefore(moment(startDate))
        ? moment(endDate).format('DD.MM.YYYY')
        : moment(startDate).format('DD.MM.YYYY')
      this.endDate = moment(endDate).isBefore(moment(startDate))
        ? moment(startDate).format('DD.MM.YYYY')
        : moment(endDate).format('DD.MM.YYYY')
      this.updateDates()
      this.isDropdownVisible = false
    },
    updateDates() {
      const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/
      if (this.startDate.match(dateRegex) && this.endDate.match(dateRegex)) {
        this.$emit('periodUpdated', {
          startDate: this.startDate,
          endDate: this.endDate
        })
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.hideDropdown)
    this.$emit('periodUpdated', {
      startDate: this.startDate,
      endDate: this.endDate
    })
  },
  destroyed() {
    document.removeEventListener('click', this.hideDropdown)
  }
}
</script>

<style scoped lang="scss">
.my-button {
  height: 38px;
  display: flex;
  align-items: center;
  border: 2px solid #37bd01;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: left;
  color: #37bd01;
}
.my-button img {
  width: 16px;
  height: 16px;
  margin-left: 2px;
}

.dropdown {
  left: 0;
  width: 100%;
  top: 38px;
  background-color: white;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dropdown h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.dropdown .date-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dropdown .date-input {
  display: flex;
  flex-direction: column;
  &:focus-within label {
    color: #37bd01;
  }
}

.dropdown .date-input label {
  color: rgba(31, 31, 31, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
}

.dropdown .date-input input {
  font-family: Roboto, sans-serif;
  border: none;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  font-size: 14px;
  margin-top: 1px;
  border-radius: 2px;
  padding: 6px 8px;
  max-width: 118px;
  background-color: #f6f6f6;
  width: 100%;
  &:focus {
    outline: 1px solid #37bd01;
  }
}
</style>
