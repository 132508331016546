<template>
  <home-template>
    <div class="merchant-container" slot="content">
      <div class="merchat-top-container">
        <v-row class="merchat-top-container-title-label">
          <div class="text-title">{{ currentMerchantName }}</div>
        </v-row>
        <v-row class="tabs tabs-with-paddings">
          <v-tabs v-model="tab">
            <v-tab
              v-for="(item, index) in tabs"
              :class="{ 'pl-0': index === 0 }"
              :key="item.key"
              v-show="!item.hideTab"
            >
              {{ item.title }}
            </v-tab>
            <v-col class="button-panel">
              <v-row>
                <v-spacer />
                <v-btn
                  v-if="auth.isParkmanAdmin() || auth.isMerchantAdmin()"
                  class="pkmn-button add-user"
                  color="primary"
                  v-show="isUsersTab"
                  @click="openAddUserForm"
                >
                  Add new user
                </v-btn>
                <v-btn
                  class="pkmn-button add-report-id mr-2"
                  color="primary"
                  outlined
                  v-show="isReportsTab && auth.isParkmanAdmin()"
                  @click="openSetReportRecipientsPopup"
                >
                  {{ $t('set_report_recipients') }} ({{
                    recipientsList.length || 0
                  }})
                </v-btn>
                <div class="three-buttons-gap">
                  <period-picker
                  v-if="isReportsTab"
                  :isDatePicker="false"
                  :initialStartDate="payoutsStartDate"
                  :initialEndDate="payoutsEndDate"
                  :minStartDate="'2025-02-01'"
                  @periodUpdated="loadReports"
                />
                </div>
                <v-btn
                  class="pkmn-button add-report-id"
                  color="primary"
                  v-show="isReportsTab && auth.isParkmanAdmin()"
                  @click="openSetReportForm"
                >
                  {{ $t('report_settings') }}
                </v-btn>
                <period-picker
                  v-if="isSalesTab"
                  @periodUpdated="updatePeriodDates"
                />
              </v-row>
            </v-col>
          </v-tabs>
        </v-row>
      </div>
      <v-row class="custom-row">
        <v-tabs-items v-model="tab">
          <v-tab-item class="tab-item map-tab">
            <v-divider class="with-horizontal-padding--map" />
            <tab-map
              :zones="zones"
              :merchant="currentMerchant"
              :shouldZoomToCenter="shouldZoomToCenter"
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding pb-0">
            <v-divider
              style="
                margin-left: 0px !important;
                margin-right: 240px !important;
              "
            />
            <licence-plate-search-table
              :parkingsList="searchedByLicencePlateList"
              @loadMore="loadMoreResults"
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-info @submit="openPayoutsList()" />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider
              style="margin-left: 0px !important; margin-right: 572px"
              :class="{
                'remove-margin': !auth.isParkmanAdmin()
              }"
            />
            <data-table-reports
              v-if="reports && reports.length"
              :data="reports"
              @download="downloadReport"
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider
              style="margin-left: 0px !important; margin-right: 148px"
              :class="{
                'remove-margin': !(
                  auth.isParkmanAdmin() || auth.isMerchantAdmin()
                )
              }"
            />
            <tab-users
              :users="users"
              @delete="openDeleteUserForm"
              @next="openAdvancedSettings()"
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-discounts 
            :discounts="discounts" 
            @edit="openEditDiscountForm"
            @close="isEcoCarDiscountFormOpening = false" 
            @showLocations="openDiscountedLocationsForm"
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-integrations :hubsList="hubsList" :wardensList="wardensList" />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-settings
              :value="currentMerchant"
              @next="openAdvancedSettings()"
              @delete="isDeleteMerchant = true"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-row>

      <v-card
        v-if="hasChanged && (auth.isParkmanAdmin() || auth.isMerchantAdmin())"
        :elevation="6"
        class="footer"
      >
        <v-card-actions class="justify-end">
          <v-btn
            class="pkmn-button next-button"
            color="primary"
            large
            @click="saveMerchant"
          >
            {{ $t('form_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <form-add-payout-id v-if="false" ref="formAddPayoutId" />

      <set-report-popup
        v-if="isAddReportFormOpening"
        :merchant="currentMerchant"
        :reportSettings="reportSettings"
        :dialogShown="isAddReportFormOpening"
        @submit="isAddReportFormOpening = false"
        @close="closeSetReportForm"
      />

      <set-report-recipients-popup
        v-if="isSetReportRecipientsOpening"
        :recipientsList="recipientsList"
        :reportSettings="reportSettings"
        @close="isSetReportRecipientsOpening = false"
        @refetchRecipients="fetchRecipients"
      />

      <form-add-user
        v-if="isAddUserFormOpening"
        @submit="addUser"
        @close="isAddUserFormOpening = false"
      />

      <form-confirmation
        ref="deleteUserConfirmation"
        warning
        :title="deleteUserTitle"
        :text="this.$t('text.delete_user')"
        :cancelText="this.$t('button.back')"
        :confirmText="this.$t('button.delete')"
        @close="clearDeletingUser"
        @confirm="deleteUser"
      />

      <form-eco-car-discount
      v-if="isEcoCarDiscountFormOpening"
      :discount="selectedDiscount"
      @close="isEcoCarDiscountFormOpening = false"
      />

      <form-holidays-popup
      v-if="isHolidaysFormOpening"
      :holidays="holidays"
      @close="isHolidaysFormOpening = false"
      />
      <form-discount-locations-popup
      v-if="isDiscountLocationsFormOpening"
      :locations="discountLocations"
      @close="isDiscountLocationsFormOpening = false"
      />
    </div>
  </home-template>
</template>

<script>
import auth from '@/auth'
import HomeTemplate from '@/templates/Locations'
import TabInfo from '@/components/Merchant/Tab/Merchant/MerchantInfo.vue'
import DataTableReports from '@/components/Merchant/DataTable/DataTableReports'
import TabUsers from '@/components/Merchant/Tab/Merchant/MerchantUsers.vue'
import TabSettings from '@/components/Merchant/Tab/Merchant/MerchantSettings.vue'
import TabDiscounts from '@/components/Merchant/Tab/Merchant/MerchantDiscounts.vue'
import TabMap from '@/components/Locations/AllLocations/MapForm.vue'
import TabIntegrations from '@/components/Locations/AddVisitorParking/Tab/TabIntegrations.vue'
import FormAddUser from '@/components/Merchant/Form/FormAddUser.vue'
import FormAddPayoutId from '@/components/Merchant/Form/FormAddPayoutId.vue'
import FormConfirmation from '@/components/ExitConfirmDialog.vue'
import FormEcoCarDiscount from '@/components/Merchant/Form/FormEcoCarDiscount.vue'
import FormHolidaysPopup from '../../components/Merchant/Form/FormHolidaysPopup.vue'
import FormDiscountLocationsPopup from '../../components/Merchant/Form/FormDiscountLocationsPopup.vue'
import LicencePlateSearchTable from '@/components/Locations/AllLocations/LicencePlateSearchTable.vue'
import PeriodPicker from '@/components/Common/PeriodPicker.vue'
import SetReportPopup from '@/components/Merchant/Form/SetReportPopup.vue'
import SetReportRecipientsPopup from '@/components/Merchant/Form/SetReportRecipientsPopup.vue'
import merchantHelpers from '@/helpers/merchant'
import * as config from '@/config/config'
import moment from 'moment'
import eventBus from '@/helpers/eventBus'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MerchantView',
  components: {
    HomeTemplate,
    TabInfo,
    DataTableReports,
    TabUsers,
    TabSettings,
    TabMap,
    FormAddUser,
    FormAddPayoutId,
    FormConfirmation,
    LicencePlateSearchTable,
    PeriodPicker,
    TabIntegrations,
    SetReportPopup,
    SetReportRecipientsPopup,
    TabDiscounts,
    FormEcoCarDiscount,
    FormHolidaysPopup,
    FormDiscountLocationsPopup
  },
  data() {
    return {
      auth: auth,
      zones: [],
      isAddReportFormOpening: false,
      isSetReportRecipientsOpening: false,
      isAddUserFormOpening: false,
      deleteUserTitle: '',
      tmpDeletingUser: {},
      tabs: [
        {
          key: 'map',
          title: this.$t('map')
        },
        {
          key: 'sales',
          title: this.$t('sales')
        },
        {
          key: 'info',
          title: this.$t('info')
        },
        {
          key: 'reports',
          title: this.$t('reports')
        },
        {
          key: 'users',
          title: this.$t('users')
        },
        {
          key: 'discounts',
          title: this.$t('discounts')
        },
        {
          key: 'integrations',
          title: this.$t('integrations'),
          hideTab: !this.showIntagrationTab
        },
        {
          key: 'advancedSettings',
          title: this.$t('advancedSettings'),
          hideTab: !auth.isParkmanAdmin()
        }
      ],
      tab: null,
      currentMerchantSnapshot: '',
      users: [],
      shouldZoomToCenter: null,
      moment: moment,
      startDate: null,
      endDate: null,
      hubsList: [],
      wardensList: [],
      fetchingInProgress: false,
      reports: null,
      reportSettings: null,
      recipientsList: [],
      payoutsStartDate: null,
      payoutsEndDate: null,
      discounts: [],
      isEcoCarDiscountFormOpening: false,
      selectedDiscount: null,
      isHolidaysFormOpening: false,
      holidays: [],
      discountLocations:[],
      isDiscountLocationsFormOpening: false
    }
  },
  methods: {
    ...mapActions(['setSearchTabsShowing', 'setSearchByLicencePeriod']),
    openAdvancedSettings() {
      // this.step = STEP.ADVANCED_SETTINGS
    },
    async getUsers() {
      const response = await merchantHelpers.fetchAllUsersForMerchant(
        this.$route.params.merchantId
      )
      if (response) {
        const users = Array.isArray(response.data) ? response.data : []
        this.users = users
      }
    },
    openAddUserForm() {
      this.isAddUserFormOpening = true
    },
    openSetReportForm() {
      this.isAddReportFormOpening = true
    },
    openSetReportRecipientsPopup() {
      this.isSetReportRecipientsOpening = true
    },
    async addUser(user) {
      this.isAddUserFormOpening = false
      if (
        this.users.filter((theUser) => theUser.email === user.email).length > 0
      ) {
        await this.$store.dispatch('setSystemMessage', {
          type: 'error',
          message: 'The user is already added'
        })
      } else {
        const addedUser = await merchantHelpers.addMerchantUser(
          this.currentMerchant.id,
          user
        )
        if (addedUser.id) {
          await this.getUsers()
          await this.$store.dispatch('setSystemMessage', {
            type: 'success',
            message: `${user.first_name} ${user.last_name} is added`
          })
        } else {
          await this.$store.dispatch('setSystemMessage', {
            type: 'error'
          })
        }
      }
    },
    openDeleteUserForm(user) {
      this.deleteUserTitle = this.$t('title.delete_user', {
        object: `${user.first_name} ${user.last_name}`
      })
      this.tmpDeletingUser = user
      this.$refs.deleteUserConfirmation.isOpening = true
    },
    closeDeleteUserForm() {
      this.deleteUserTitle = ''
    },
    async deleteUser() {
      const succeed = await merchantHelpers.deleteMerchantUser(
        this.currentMerchant.id,
        this.tmpDeletingUser.id
      )
      if (succeed) {
        await this.getUsers()
        await this.$store.dispatch('setSystemMessage', {
          type: 'delete',
          message: this.$t('removed_confirm_message', {
            object: `${this.tmpDeletingUser.first_name} ${this.tmpDeletingUser.last_name}`
          })
        })
      } else {
        await this.$store.dispatch('setSystemMessage', {
          type: 'error'
        })
      }
      this.clearDeletingUser()
    },
    clearDeletingUser() {
      this.tmpDeletingUser = {}
    },
    async saveMerchant() {
      await this.$store.dispatch('setShowOverlay', true)
      if (this.currentMerchant.vat_percentage === "") {
        this.currentMerchant.vat_percentage = null
      }
      const merchant = await merchantHelpers.updateMerchant(
        this.currentMerchant
      )
      if (!this.fetchingInProgress) {
        await this.$store.dispatch('setShowOverlay', false)
      }
      if (merchant) {
        await this.$store.dispatch('setSystemMessage', {
          message: 'Changes are updated',
          type: 'success'
        })
        this.currentMerchantSnapshot = JSON.stringify(merchant)
      }
    },
    async fetchMerchant() {
      await this.$store.dispatch('setShowOverlay', true)
      const merchant = await merchantHelpers.fetchMerchant(
        this.$route.params.merchantId
      )
      if (!merchant) {
        await this.$router.push({ name: 'AllMerchants' })
        await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_ALL)
        return
      }
      this.currentMerchantSnapshot = JSON.stringify(merchant)
      if (!this.fetchingInProgress) {
        await this.$store.dispatch('setShowOverlay', false)
      }
      await this.getUsers()
    },
    async getLocations() {
      const locations = await merchantHelpers.getLocationsByMerchant(
        this.$route.params.merchantId
      )
      this.zones = locations
      await this.$store.dispatch('setMerchantLocations', locations)
      this.shouldZoomToCenter = moment()
    },
    showSearchTab(tabNumber) {
      this.tab = tabNumber
    },
    updatePeriodDates({ startDate, endDate }) {
      if (
        this.searchByLicenceStartDate !== startDate &&
        this.searchByLicenceEndDate !== endDate
      ) {
        this.setSearchByLicencePeriod({ startDate, endDate })
        eventBus.$emit('reloadMerchantLicencePlates')
      }
    },
    async loadReports( { startDate, endDate }) {
      this.payoutsStartDate = startDate
      this.payoutsEndDate = endDate
      const data = await merchantHelpers.fetchHistoryPayouts(
        this.$route.params.merchantId, moment.utc(startDate, 'DD.MM.YYYY').unix(), moment.utc(endDate, 'DD.MM.YYYY').endOf('day').unix()
      )
      if (data) {
        this.reports = data
      }
    },
    loadMoreResults() {
      eventBus.$emit('loadMerchantLicencePlates')
    },
    async downloadReport(reportId) {
      await merchantHelpers.download(this.$route.params.merchantId, reportId)
    },
    async getWardensList() {
      this.wardensList = await merchantHelpers.getWardensList(this.wardensUrl)
    },
    async getHubsList() {
      this.hubsList = await merchantHelpers.getMerchantHubsList(
        this.$route.params.merchantId
      )
    },
    async fetchReportSettings() {
      const settings = await merchantHelpers.fetchReportSettings(
        this.$route.params.merchantId
      )
      if (settings) {
        this.reportSettings = settings[0]
      }
    },
    async fetchRecipients() {
      if (this.reportSettings) {
        const id = this.reportSettings.id
        this.recipientsList = await merchantHelpers.getReceivers(id
        )
      }
    },
    closeSetReportForm(report) {
      this.reportSettings = report
      this.isAddReportFormOpening = false
    },
    async openEditDiscountForm(discount) {
      this.selectedDiscount = discount
      if (discount.discount_type === 'eco_car_discount') {
        this.isEcoCarDiscountFormOpening = true
      }
      if (discount.discount_type === 'holiday_discount') {
        await this.fetchHolidays(discount.list_id)
        this.isHolidaysFormOpening = true
      }
      
    },
    async fetchMerchantDiscounts() {
      const discounts = await merchantHelpers.fetchMerchantDiscounts(
        this.$route.params.merchantId
      )
      this.discounts = discounts
    },
    async fetchHolidays(id) {
      const holidays = await merchantHelpers.fetchMerchantHolidays(
        this.$route.params.merchantId,
        id
      )
      this.holidays = holidays
    },
    async fetchDiscountedLocations(listId) {
      const locations = await merchantHelpers.fetchDiscountedLocations(
        this.$route.params.merchantId,
        listId
      )
      this.discountLocations = locations
      this.isDiscountLocationsFormOpening = true
    },
    async openDiscountedLocationsForm(item) {
      await this.fetchDiscountedLocations(item.discount_template_id)
      
    }
  },
  computed: {
    ...mapGetters([
      'searchedByLicencePlateList',
      'searchByLicenceStartDate',
      'searchByLicenceEndDate',
      'filteredLocations'
    ]),
    isSalesTab() {
      return this.tabs[this.tab]?.key === 'sales'
    },
    isReportsTab() {
      return this.tabs[this.tab]?.key === 'reports'
    },
    isUsersTab() {
      return this.tabs[this.tab]?.key === 'users'
    },
    currentMerchant() {
      return this.$store.getters.currentMerchant
    },
    hasChanged() {
      return (
        this.currentMerchantSnapshot !== '' &&
        this.currentMerchantSnapshot !== JSON.stringify(this.currentMerchant)
      )
    },
    currentMerchantName() {
      if (this.currentMerchant) {
        return this.currentMerchant.name
      } else {
        return 'Loading'
      }
    },
    wardensUrl() {
      return this.$route.name === 'Merchant'
        ? auth.isParkmanAdmin()
          ? `admin/merchants/${this.$route.params.merchantId}/warden`
          : `merchants/${this.$route.params.merchantId}/warden`
        : auth.isParkmanAdmin()
          ? `admin/merchants/${this.$route.params.merchantId}/locations/${this.$route.params.locationId}/warden`
          : `merchants/${this.$route.params.merchantId}/locations/${this.$route.params.locationId}/warden`
    },
    showIntagrationTab() {
      return !!this.hubsList?.length || !!this.wardensList?.length
    }
  },
  watch: {
    async '$route.params.merchantId'() {
      this.fetchMerchant()
      this.tab = 0
      await this.getLocations()
      await Promise.all([
        this.getHubsList(),
        this.getWardensList(),
        this.fetchReportSettings(),
        this.fetchRecipients(),
        this.fetchMerchantDiscounts()
      ])
    },
    searchByLicenceStartDate(date) {
      this.startDate = date
    },
    searchByLicenceEndDate(date) {
      this.endDate = date
    },
    filteredLocations(newVal) {
      if (newVal.length) {
        this.zones = newVal
      } else {
        this.getLocations()
      }
    },
    async tab(tabNumber) {
      if (tabNumber === 3) {
        await this.fetchReportSettings()
        await this.fetchRecipients()
      }

      if (this.tabs[tabNumber]?.key === 'discounts') {
        await this.fetchMerchantDiscounts()
      }
    }
  },
  async created() {
    this.fetchingInProgress = true
    this.$store.dispatch('setShowOverlay', true)
    if (this.$store.getters.countries.length === 0) {
      await merchantHelpers.fetchCountries()
    }
    if (
      this.$store.getters.currentMerchant === null ||
      this.currentMerchant.id !== this.$route.params.merchantId
    ) {
      await this.fetchMerchant()
    }
    this.currentMerchantSnapshot = JSON.stringify(
      this.$store.getters.currentMerchant
    )
    if (this.zones.length === 0) {
      await this.getLocations()
    }
  },
  async mounted() {
    eventBus.$on('showSearchTab', (tabNumber) => {
      this.showSearchTab(tabNumber)
    })
    this.startDate = this.searchByLicenceStartDate
    this.endDate = this.searchByLicenceEndDate
    await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)

    if (this.$store.getters.countries.length === 0) {
      await merchantHelpers.fetchCountries()
    }

    if (
      this.$store.getters.currentMerchant === null ||
      this.currentMerchant.id !== this.$route.params.merchantId
    ) {
      await this.fetchMerchant()
    } else {
      await this.getUsers()
    }
    await Promise.all([this.getHubsList(), this.getWardensList()]).finally(
      () => {
        this.fetchingInProgress = false
        this.$store.dispatch('setShowOverlay', false)
      }
    )

    if (this.zones.length === 0) {
      await this.getLocations()
    }
  },
  beforeDestroy() {
    this.setSearchTabsShowing(true)
  },
  destroyed() {
    this.$store.dispatch('setCurrentMerchant', null)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';
.add-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 122px;
  height: 38px !important;
}

.add-report-id {
  height: 38px !important;
}

.v-window__container {
  height: 100%;
}

.button-panel {
  padding: 0px 68px 40px;
}

.three-buttons-gap {
  padding-right: 8px;
}

.tab-item {
  overflow-y: auto;
  transition: false;
}

.footer {
  position: absolute;
  padding: 8px 32px;
  bottom: 0;
  right: 0;
  left: $sidebar-width;
}

.next-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  width: 168px;
  height: 46px;
}
.map-tab {
  margin-right: 12px !important;
}

.remove-margin {
  margin-right: 0 !important;
}
</style>
